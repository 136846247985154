<template>
  <div class="exercise-wrapper page">
    <b-row class="subject-info">
      <b-col cols="3" class="d-flex justify-content-center align-items-center">
        <span class="brand-logo">
          <b-img :src="appLogoImage" alt="logo" height="50%" />
        </span>
        <h3 class="data-text ml-1" style="font-size: 32px">GBT</h3>
      </b-col>
      <b-col cols="4" class="d-flex justify-content-end">
        <span class="data-text ml-3">{{ $t("Name") }} {{ $t("Last Name") }}:</span>
        <span class="data-text ml-1">{{ userData.name }}</span>
        <!-- <span class="data-text">{{ $t("Attention") }}</span> -->
      </b-col>
      <b-col cols="4" class="d-flex justify-content-end">
        <span class="data-text">{{ $t("Age") }}:</span>
        <span class="data-text ml-1">{{ userData.age }}</span>
      </b-col>
    </b-row>
    <b-row class="w-100 flex-nowrap">
      <div class="border-box">
        <b-row class="justify-content-between">
          <b-col cols="9">
            <vue-apex-charts
              height="180"
              width="100%"
              :options="tATLOptions"
              :series="tATL"
              :key="refreshKey"
            />
          </b-col>
          <b-col
            cols="3"
            class="d-flex flex-column justify-content-center age-class-avgs"
          >
            <b-row class="justify-content-between" style="">
              <span>{{ userData.age }} Yaş</span>
              <span>Dağılım</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.attention == 5 ? "->" : "" }}A Grubu:</span>
              <span>{{ ((spread.att[4] * 100) / spread.att[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.attention == 4 ? "->" : "" }}B Grubu:</span>
              <span>{{ ((spread.att[3] * 100) / spread.att[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.attention == 3 ? "->" : "" }}C Grubu:</span>
              <span>{{ ((spread.att[2] * 100) / spread.att[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.attention == 2 ? "->" : "" }}D Grubu:</span>
              <span>{{ ((spread.att[1] * 100) / spread.att[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.attention <= 1 ? "->" : "" }}E Grubu:</span>
              <span>{{ ((spread.att[0] * 100) / spread.att[5]).toFixed(1) }}%</span>
            </b-row>
            <!--<b-row class="justify-content-between">
              <span>{{ ((spread.att[4] * 100) / spread.att[5]).toFixed(1) }}%</span>
              <span>{{ userTest.attention == 5 ? "->" : "" }}A</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ ((spread.att[3] * 100) / spread.att[5]).toFixed(1) }}%</span>
              <span style="text-align: center">B<feather-icon v-if="userTest.attention == 4"
                  icon="ChevronsLeftIcon" /></span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ ((spread.att[2] * 100) / spread.att[5]).toFixed(1) }}%</span>
              <span>{{ userTest.attention == 3 ? "->" : "" }}C</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ ((spread.att[1] * 100) / spread.att[5]).toFixed(1) }}%</span>
              <span>{{ userTest.attention == 2 ? "->" : "" }}D</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ ((spread.att[0] * 100) / spread.att[5]).toFixed(1) }}% </span>
              <span>{{ userTest.attention <= 1 ? "->" : "" }}E</span>
            </b-row> -->
          </b-col>
        </b-row>
        <b-row class="justify-content-around ml-1 mr-1" style="margin-top: -10px">
          <b-col cols="6">
            <table class="undergraph-table attention-table student-table">
              <thead>
                <tr class="header-row first">
                  <th colspan="3" style="text-align: center">{{ userData.name }}</th>
                </tr>
                <tr class="header-row second">
                  <th>Soru</th>
                  <th>Cevap</th>
                  <th>Süre</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{{ attAns[0].right }}</td>
                  <td>{{ attAns[0].time }} sn</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>{{ attAns[1].right }}</td>
                  <td>{{ attAns[1].time }} sn</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>{{ attAns[2].right }}</td>
                  <td>{{ attAns[2].time }} sn</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>{{ attAns[3].right }}</td>
                  <td>{{ attAns[3].time }} sn</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>{{ attAns[4].right }}</td>
                  <td>{{ attAns[4].time }} sn</td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="6">
            <table class="undergraph-table attention-table avg-table">
              <thead>
                <tr class="header-row first">
                  <th colspan="3" style="text-align: center">
                    {{ userData.age }} Yaş Ortalama
                  </th>
                </tr>
                <tr class="header-row second">
                  <th>Doğru Oranı %</th>
                  <th>Süre Ort.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ pAvgs[0] }} %</td>
                  <td>{{ tAvgs[0] }} sn</td>
                </tr>
                <tr>
                  <td>{{ pAvgs[1] }} %</td>
                  <td>{{ tAvgs[1] }} sn</td>
                </tr>
                <tr>
                  <td>{{ pAvgs[2] }} %</td>
                  <td>{{ tAvgs[2] }} sn</td>
                </tr>
                <tr>
                  <td>{{ pAvgs[3] }} %</td>
                  <td>{{ tAvgs[3] }} sn</td>
                </tr>
                <tr>
                  <td>{{ pAvgs[4] }} %</td>
                  <td>{{ tAvgs[4] }} sn</td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </div>
      <div class="border-box">
        <b-row class="justify-content-between">
          <b-col cols="9">
            <vue-apex-charts
              height="180"
              width="100%"
              :options="tLTLOptions"
              :series="tLTL"
              :key="refreshKey"
            />
          </b-col>
          <b-col
            cols="3"
            class="d-flex flex-column justify-content-center age-class-avgs"
          >
            <b-row class="justify-content-between" style="">
              <span>{{ userData.age }} Yaş</span>
              <span>Dağılım</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.logic == 5 ? "->" : "" }}A Grubu:</span>
              <span>{{ ((spread.log[4] * 100) / spread.log[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.logic == 4 ? "->" : "" }}B Grubu:</span>
              <span>{{ ((spread.log[3] * 100) / spread.log[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.logic == 3 ? "->" : "" }}C Grubu:</span>
              <span>{{ ((spread.log[2] * 100) / spread.log[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.logic == 2 ? "->" : "" }}D Grubu:</span>
              <span>{{ ((spread.log[1] * 100) / spread.log[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.logic <= 1 ? "->" : "" }}E Grubu:</span>
              <span>{{ ((spread.log[0] * 100) / spread.log[5]).toFixed(1) }}%</span>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="justify-content-around ml-1 mr-1" style="margin-top: -10px">
          <!--
          <b-col cols="10" class="justify-content-around">
            <b-row class="justify-content-around">
              <span>Soru 1: {{ logAns[0].right }} (Doğru: {{ pAvgs[5] }}%)</span>
              <span>Süre: {{ logAns[0].time }} sn (Ort:{{ tAvgs[5] }} sn)</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>Soru 2: {{ logAns[1].right }} (Doğru: {{ pAvgs[6] }}%)</span>
              <span>Süre: {{ logAns[1].time }} sn (Ort:{{ tAvgs[6] }} sn)</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>Soru 3: {{ logAns[2].right }} (Doğru: {{ pAvgs[7] }}%)</span>
              <span>Süre: {{ logAns[2].time }} sn (Ort:{{ tAvgs[7] }} sn)</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>Soru 4: {{ logAns[3].right }} (Doğru: {{ pAvgs[8] }}%)</span>
              <span>Süre: {{ logAns[3].time }} sn (Ort:{{ tAvgs[8] }} sn)</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>Soru 5: {{ logAns[4].right }} (Doğru: {{ pAvgs[9] }}%)</span>
              <span>Süre: {{ logAns[4].time }} sn (Ort:{{ tAvgs[9] }} sn)</span>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-row class="justify-content-around">
              <span>A Grubu:</span>
              <span>{{ (spread.log[4] * 100) / spread.log[5] }}%</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>B Grubu:</span>
              <span>{{ (spread.log[3] * 100) / spread.log[5] }}%</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>C Grubu:</span>
              <span>{{ (spread.log[2] * 100) / spread.log[5] }}%</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>D Grubu:</span>
              <span>{{ (spread.log[1] * 100) / spread.log[5] }}%</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>E Grubu:</span>
              <span>{{ (spread.log[0] * 100) / spread.log[5] }}%</span>
            </b-row>
          </b-col>
      -->
          <table class="undergraph-table attention-table student-table">
            <thead>
              <tr class="header-row first">
                <th></th>
                <th colspan="2" style="text-align: center; border-right: 1px solid #000">
                  {{ userData.name }}
                </th>
                <th colspan="2" style="text-align: center">
                  {{ userData.age }} Yaş Ortalama
                </th>
              </tr>
              <tr class="header-row second">
                <th>Soru</th>
                <th>Cevap</th>
                <th style="border-right: 1px solid #000">Süre</th>
                <th>Doğru Oranı %</th>
                <th>Süre Ort.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{{ logAns[0].right }}</td>
                <td>{{ logAns[0].time }} sn</td>
                <td>{{ pAvgs[5] }} %</td>
                <td>{{ tAvgs[5] }} sn</td>
              </tr>
              <tr>
                <td>2</td>
                <td>{{ logAns[1].right }}</td>
                <td>{{ logAns[1].time }} sn</td>
                <td>{{ pAvgs[6] }} %</td>
                <td>{{ tAvgs[6] }} sn</td>
              </tr>
              <tr>
                <td>3</td>
                <td>{{ logAns[2].right }}</td>
                <td>{{ logAns[2].time }} sn</td>
                <td>{{ pAvgs[7] }} %</td>
                <td>{{ tAvgs[7] }} sn</td>
              </tr>
              <tr>
                <td>4</td>
                <td>{{ logAns[3].right }}</td>
                <td>{{ logAns[3].time }} sn</td>
                <td>{{ pAvgs[8] }} %</td>
                <td>{{ tAvgs[8] }} sn</td>
              </tr>
              <tr>
                <td>5</td>
                <td>{{ logAns[4].right }}</td>
                <td>{{ logAns[4].time }} sn</td>
                <td>{{ pAvgs[9] }} %</td>
                <td>{{ tAvgs[9] }} sn</td>
              </tr>
            </tbody>
          </table>
        </b-row>
      </div>
    </b-row>
    <b-row class="w-100 flex-nowrap">
      <div class="border-box">
        <b-row class="justify-content-between">
          <b-col cols="9">
            <vue-apex-charts
              height="180"
              width="100%"
              :options="tMTLOptions"
              :series="tMTL"
              :key="refreshKey"
            />
          </b-col>
          <b-col
            cols="3"
            class="d-flex flex-column justify-content-center age-class-avgs"
          >
            <b-row class="justify-content-between" style="">
              <span>{{ userData.age }} Yaş</span>
              <span>Dağılım</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.memory == 5 ? "->" : "" }}A Grubu:</span>
              <span>{{ ((spread.mem[4] * 100) / spread.mem[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.memory == 4 ? "->" : "" }}B Grubu:</span>
              <span>{{ ((spread.mem[3] * 100) / spread.mem[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.memory == 3 ? "->" : "" }}C Grubu:</span>
              <span>{{ ((spread.mem[2] * 100) / spread.mem[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.memory == 2 ? "->" : "" }}D Grubu:</span>
              <span>{{ ((spread.mem[1] * 100) / spread.mem[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.memory <= 1 ? "->" : "" }}E Grubu:</span>
              <span>{{ ((spread.mem[0] * 100) / spread.mem[5]).toFixed(1) }}%</span>
            </b-row>
            <!--<b-row class="justify-content-between">
              <span>{{ ((spread.att[4] * 100) / spread.att[5]).toFixed(1) }}%</span>
              <span>{{ userTest.attention == 5 ? "->" : "" }}A</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ ((spread.att[3] * 100) / spread.att[5]).toFixed(1) }}%</span>
              <span style="text-align: center">B<feather-icon v-if="userTest.attention == 4"
                  icon="ChevronsLeftIcon" /></span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ ((spread.att[2] * 100) / spread.att[5]).toFixed(1) }}%</span>
              <span>{{ userTest.attention == 3 ? "->" : "" }}C</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ ((spread.att[1] * 100) / spread.att[5]).toFixed(1) }}%</span>
              <span>{{ userTest.attention == 2 ? "->" : "" }}D</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ ((spread.att[0] * 100) / spread.att[5]).toFixed(1) }}% </span>
              <span>{{ userTest.attention <= 1 ? "->" : "" }}E</span>
            </b-row> -->
          </b-col>
        </b-row>
        <b-row class="justify-content-around ml-1 mr-1" style="margin-top: -10px">
          <b-col cols="6">
            <table class="undergraph-table attention-table student-table">
              <thead>
                <tr class="header-row first">
                  <th colspan="3" style="text-align: center">{{ userData.name }}</th>
                </tr>
                <tr class="header-row second">
                  <th>Soru</th>
                  <th>Cevap</th>
                  <th>Süre</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{{ memAnsCalc(0) }}</td>
                  <td>{{ memAnsTimeCalc(0) }} sn</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>{{ memAnsCalc(1) }}</td>
                  <td>{{ memAnsTimeCalc(1) }} sn</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>{{ memAnsCalc(2) }}</td>
                  <td>{{ memAnsTimeCalc(2) }} sn</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>{{ memAnsCalc(3) }}</td>
                  <td>{{ memAnsTimeCalc(3) }} sn</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>{{ memAnsCalc(4) }}</td>
                  <td>{{ memAnsTimeCalc(4) }} sn</td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="6">
            <table class="undergraph-table attention-table avg-table">
              <thead>
                <tr class="header-row first">
                  <th colspan="3" style="text-align: center">
                    {{ userData.age }} Yaş Ortalama
                  </th>
                </tr>
                <tr class="header-row second">
                  <th>Doğru Oranı %</th>
                  <th>Süre Ort.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ pMemAvgs[0] }} %</td>
                  <td>{{ pMemTimes[0] }} sn</td>
                </tr>
                <tr>
                  <td>{{ pMemAvgs[1] }} %</td>
                  <td>{{ pMemTimes[1] }} sn</td>
                </tr>
                <tr>
                  <td>{{ pMemAvgs[2] }} %</td>
                  <td>{{ pMemTimes[2] }} sn</td>
                </tr>
                <tr>
                  <td>{{ pMemAvgs[3] }} %</td>
                  <td>{{ pMemTimes[3] }} sn</td>
                </tr>
                <tr>
                  <td>{{ pMemAvgs[4] }} %</td>
                  <td>{{ pMemTimes[4] }} sn</td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </div>
      <div class="border-box">
        <b-row class="justify-content-between">
          <b-col cols="9">
            <vue-apex-charts
              height="180"
              width="100%"
              :options="tCTLOptions"
              :series="tCTL"
              :key="refreshKey"
            />
          </b-col>
          <b-col
            cols="3"
            class="d-flex flex-column justify-content-center age-class-avgs"
          >
            <b-row class="justify-content-between" style="">
              <span>{{ userData.age }} Yaş</span>
              <span>Dağılım</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.concentration == 5 ? "->" : "" }}A Grubu:</span>
              <span>{{ ((spread.con[4] * 100) / spread.con[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.concentration == 4 ? "->" : "" }}B Grubu:</span>
              <span>{{ ((spread.con[3] * 100) / spread.con[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.concentration == 3 ? "->" : "" }}C Grubu:</span>
              <span>{{ ((spread.con[2] * 100) / spread.con[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.concentration == 2 ? "->" : "" }}D Grubu:</span>
              <span>{{ ((spread.con[1] * 100) / spread.con[5]).toFixed(1) }}%</span>
            </b-row>
            <b-row class="justify-content-between">
              <span>{{ userTest.concentration <= 1 ? "->" : "" }}E Grubu:</span>
              <span>{{ ((spread.con[0] * 100) / spread.con[5]).toFixed(1) }}%</span>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="justify-content-around ml-1 mr-1" style="margin-top: -10px">
          <!--
          <b-col cols="10" class="justify-content-around">
            <b-row class="justify-content-around">
              <span>Soru 1: {{ logAns[0].right }} (Doğru: {{ pAvgs[5] }}%)</span>
              <span>Süre: {{ logAns[0].time }} sn (Ort:{{ tAvgs[5] }} sn)</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>Soru 2: {{ logAns[1].right }} (Doğru: {{ pAvgs[6] }}%)</span>
              <span>Süre: {{ logAns[1].time }} sn (Ort:{{ tAvgs[6] }} sn)</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>Soru 3: {{ logAns[2].right }} (Doğru: {{ pAvgs[7] }}%)</span>
              <span>Süre: {{ logAns[2].time }} sn (Ort:{{ tAvgs[7] }} sn)</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>Soru 4: {{ logAns[3].right }} (Doğru: {{ pAvgs[8] }}%)</span>
              <span>Süre: {{ logAns[3].time }} sn (Ort:{{ tAvgs[8] }} sn)</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>Soru 5: {{ logAns[4].right }} (Doğru: {{ pAvgs[9] }}%)</span>
              <span>Süre: {{ logAns[4].time }} sn (Ort:{{ tAvgs[9] }} sn)</span>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-row class="justify-content-around">
              <span>A Grubu:</span>
              <span>{{ (spread.log[4] * 100) / spread.log[5] }}%</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>B Grubu:</span>
              <span>{{ (spread.log[3] * 100) / spread.log[5] }}%</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>C Grubu:</span>
              <span>{{ (spread.log[2] * 100) / spread.log[5] }}%</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>D Grubu:</span>
              <span>{{ (spread.log[1] * 100) / spread.log[5] }}%</span>
            </b-row>
            <b-row class="justify-content-around">
              <span>E Grubu:</span>
              <span>{{ (spread.log[0] * 100) / spread.log[5] }}%</span>
            </b-row>
          </b-col>
      -->
          <table class="undergraph-table attention-table student-table">
            <thead>
              <tr class="header-row first">
                <th></th>
                <th colspan="2" style="text-align: center; border-right: 1px solid #000">
                  {{ userData.name }}
                </th>
                <th colspan="2" style="text-align: center">
                  {{ userData.age }} Yaş Ortalama
                </th>
              </tr>
              <tr class="header-row second">
                <th>Soru</th>
                <th>Cevap</th>
                <th style="border-right: #000 1px solid">Süre</th>
                <th>Doğru Oranı %</th>
                <th>Süre Ort.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{{ conAns[0].right }}</td>
                <td>{{ conAns[0].time }} sn</td>
                <td>{{ pAvgs[10] }} %</td>
                <td>{{ tAvgs[10] }} sn</td>
              </tr>
              <tr>
                <td>2</td>
                <td>{{ conAns[1].right }}</td>
                <td>{{ conAns[1].time }} sn</td>
                <td>{{ pAvgs[11] }} %</td>
                <td>{{ tAvgs[11] }} sn</td>
              </tr>
              <tr>
                <td>3</td>
                <td>{{ conAns[2].right }}</td>
                <td>{{ conAns[2].time }} sn</td>
                <td>{{ pAvgs[12] }} %</td>
                <td>{{ tAvgs[12] }} sn</td>
              </tr>
              <tr>
                <td>4</td>
                <td>{{ conAns[3].right }}</td>
                <td>{{ conAns[3].time }} sn</td>
                <td>{{ pAvgs[13] }} %</td>
                <td>{{ tAvgs[13] }} sn</td>
              </tr>
              <tr>
                <td>5</td>
                <td>{{ conAns[4].right }}</td>
                <td>{{ conAns[4].time }} sn</td>
                <td>{{ pAvgs[14] }} %</td>
                <td>{{ tAvgs[14] }} sn</td>
              </tr>
            </tbody>
          </table>
        </b-row>
      </div>
    </b-row>
  </div>
</template>
<script>
//<vue-apex-charts height="180" width="100%" :options="tMTLOptions" :series="tMTL" :key="refreshKey" />
//<vue-apex-charts height="180" width="100%" :options="tCTLOptions" :series="tCTL" :key="refreshKey" />
import axios from "@axios";
import { BRow, BCol, BImg } from "bootstrap-vue";
import {
  tATLOptions,
  tLTLOptions,
  tCTLOptions,
  tMTLOptions,
  tGTLOptions,
} from "./compareLineChartOptions";
import VueApexCharts from "vue-apexcharts";
export default {
  components: { BRow, BCol, VueApexCharts, BImg },
  data() {
    return {
      appLogoImage: require("@/assets/images/logo/dikologo.svg"),
      userData: {},
      refreshKey: 0,
      tATLOptions,
      tLTLOptions,
      tCTLOptions,
      tMTLOptions,
      tGTLOptions,
      tATL: [
        {
          name: "Danışan",
          data: [],
        },
        {
          name: "Genel",
          data: [],
        },
      ],
      tLTL: [
        {
          name: "Danışan",
          data: [],
        },
        {
          name: "Genel",
          data: [],
        },
      ],
      tCTL: [
        {
          name: "Danışan",
          data: [],
        },
        {
          name: "Genel",
          data: [],
        },
      ],
      tMTL: [
        {
          name: "Danışan",
          data: [],
        },
        {
          name: "Genel",
          data: [],
        },
      ],
      tGTL: [
        {
          name: "Danışan",
          data: [],
        },
        {
          name: "Genel",
          data: [],
        },
      ],
      pAvgs: [],
      tAvgs: [],
      spread: {},
      attAns: [],
      logAns: [],
      conAns: [],
      memAns: [],
      genAns: [],
      userTest: {},
      pMemAvgs: [0, 0, 0, 0, 0],
      pMemTimes: [0, 0, 0, 0, 0],
    };
  },
  created() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("dark-layout");
    body.style.background = "#fff";
    let app = document.getElementById("app");
    app.classList.remove("h-100");
    axios.put(`/test/result/${this.$route.params.testid}`).then(({ data }) => {
      this.userTest = data.test;
      this.userData = data.test.solvedBy;
      // this.tATL.data = data.tATL.map((item) => {
      //   return item.move;
      // });
      let aTL = this.timelineinator(data.tATL, this.tATLOptions);
      this.tATL[0].data = aTL[0];
      this.attAns = aTL[1];
      this.tATL[1].data = this.timelineinator(data.oATL, this.tATLOptions, true);
      let lTL = this.timelineinator(data.tLTL, this.tLTLOptions);
      this.tLTL[0].data = lTL[0];
      this.logAns = lTL[1];
      this.tLTL[1].data = this.timelineinator(data.oLTL, this.tLTLOptions, true);
      let cTL = this.timelineinator(data.tCTL, this.tCTLOptions);
      this.tCTL[0].data = cTL[0];
      this.conAns = cTL[1];
      this.tCTL[1].data = this.timelineinator(data.oCTL, this.tCTLOptions, true);
      let mTL = this.timelineinator(data.tMTL, this.tMTLOptions, false, true);
      this.tMTL[0].data = mTL[0];
      this.memAns = mTL[1];
      this.tMTL[1].data = this.timelineinator(data.oMTL, this.tMTLOptions, true, true);
      // let gTL = this.timelineinator(data.tGTL, this.tGTLOptions);
      // this.tGTL[0].data = gTL[0];
      // this.genAns = gTL[1];
      // this.tGTL[1].data = this.timelineinator(data.oGTL, this.tGTLOptions, true);
      this.pAvgs = data.pAvgs;
      this.tAvgs = data.tAvgs;
      this.spread = data.spread;
      this.refreshKey++;
      for (let i = 15; i < 55; i++) {
        if (i < 19) {
          this.pMemAvgs[0] += parseInt(this.pAvgs[i]);
          this.pMemTimes[0] =
            parseInt(this.tAvgs[i]) > this.pMemTimes[0]
              ? parseInt(this.tAvgs[i])
              : this.pMemTimes[0];
        } else if (i < 25) {
          this.pMemAvgs[1] += parseInt(this.pAvgs[i]);
          this.pMemTimes[1] =
            parseInt(this.tAvgs[i]) > this.pMemTimes[1]
              ? parseInt(this.tAvgs[i])
              : this.pMemTimes[1];
        } else if (i < 33) {
          this.pMemAvgs[2] += parseInt(this.pAvgs[i]);
          this.pMemTimes[2] =
            parseInt(this.tAvgs[i]) > this.pMemTimes[2]
              ? parseInt(this.tAvgs[i])
              : this.pMemTimes[2];
        } else if (i < 43) {
          this.pMemAvgs[3] += parseInt(this.pAvgs[i]);
          this.pMemTimes[3] =
            parseInt(this.tAvgs[i]) > this.pMemTimes[3]
              ? parseInt(this.tAvgs[i])
              : this.pMemTimes[3];
        } else {
          this.pMemAvgs[4] += parseInt(this.pAvgs[i]);
          this.pMemTimes[4] =
            parseInt(this.tAvgs[i]) > this.pMemTimes[4]
              ? parseInt(this.tAvgs[i])
              : this.pMemTimes[4];
        }
      }
      for (let i = 0; i < 5; i++) {
        this.pMemAvgs[i] = (this.pMemAvgs[i] / (4 + i * 2)).toFixed(2);
        this.pMemTimes[i] = this.pMemTimes[i].toFixed(2);
      }
    });
  },
  methods: {
    memAnsCalc(page) {
      let rightAns = 0;
      let firstPage = page == 0;
      let pageOffset = 0;
      let tempPage = page;
      while (tempPage > 0) {
        // console.log(tempPage, pageOffset);
        pageOffset += 2 + tempPage * 2;
        tempPage -= 1;
      }
      for (let i = 0; i < 4 + page * 2; i++) {
        if (!firstPage) {
          if (this.memAns[i + pageOffset].right == "Doğru") rightAns++;
        } else {
          if (this.memAns[i].right == "Doğru") rightAns++;
        }
      }
      return `${rightAns}/${4 + page * 2} Doğru`;
    },
    memAnsTimeCalc(page) {
      let firstPage = page == 0;
      let time = 0;
      let pageOffset = 0;
      let tempPage = page;
      // console.log("--------------------");
      while (tempPage > 0) {
        // console.log(tempPage, pageOffset);
        pageOffset += 2 + tempPage * 2;
        tempPage -= 1;
      }
      // console.log("----------x----------");
      // console.log(page, pageOffset);
      for (let i = 0; i < 4 + page * 2; i++) {
        // console.log(page, time, i, i + pageOffset, this.memAns[i + pageOffset].time);
        if (!firstPage) {
          time += this.memAns[i + pageOffset].time;
        } else {
          time += this.memAns[i].time;
          // if (this.memAns[i].right == "Doğru") rightAns++;
        }
      }
      return time;
    },
    timelineinator(moveArr, options, isCompare = false, isMem = false, full = false) {
      // let baseOptions = this[options];
      let dataArr = new Array(full ? 600 : 150).fill(null);
      let ansArr = [];
      dataArr[0] = isMem ? -5 : 16;
      let lastChanged = 0;
      for (let i = 0; i < moveArr.length; i++) {
        if (moveArr[i].time > (full ? 600 : 150)) continue;
        // console.log(`from ${lastChanged} to ${moveArr[i].time}`);
        let changeArea = moveArr[i].time - lastChanged;
        let changeNormalise = (moveArr[i].move * 10) / Math.max(changeArea, 1);
        if (options.title.text != "Hafıza Başarı Grafiği") {
          if (!isCompare) {
            if (i == 0) {
              ansArr.push({
                right:
                  moveArr[i].move > 0
                    ? "Doğru"
                    : moveArr[i].time == 30 || moveArr[i].time == 0
                    ? "Boş"
                    : "Yanlış",
                time: moveArr[i].time,
              });
            } else {
              ansArr.push({
                right:
                  moveArr[i].move > 0
                    ? "Doğru"
                    : moveArr[i].time - moveArr[i - 1]?.time == 30 ||
                      moveArr[i].time - moveArr[i - 1]?.time == 0
                    ? "Boş"
                    : "Yanlış",
                time: moveArr[i].time - moveArr[i - 1]?.time,
              });
            }
          }
        } else {
          if (!isCompare) {
            if (i == 0) {
              ansArr.push({
                right:
                  moveArr[i].time == 30
                    ? "Boş"
                    : moveArr[i].move >= 0
                    ? "Doğru"
                    : "Yanlış",
                time: moveArr[i].time,
              });
            } else {
              ansArr.push({
                right:
                  moveArr[i].time - moveArr[i - 1]?.time == 30
                    ? "Boş"
                    : moveArr[i].move > 0
                    ? "Doğru"
                    : "Yanlış",
                time: moveArr[i].time - moveArr[i - 1]?.time,
              });
            }
          }
        }
        for (let j = lastChanged + 1; j <= moveArr[i].time; j++) {
          // console.log(j, dataArr[j - 1], changeNormalise);
          dataArr[j] = dataArr[j - 1] + changeNormalise;
        }
        lastChanged = moveArr[i].time;
        if (
          (!isCompare || i === moveArr.length - 1) &&
          options.title.text != "Hafıza Başarı Grafiği"
        ) {
          if (Array.isArray(options.annotations.points)) {
            options.annotations.points.push({
              x: moveArr[i].time,
              y: dataArr[lastChanged],
              strokeDashArray: 0,
              borderColor: "#000",
              borderWidth: 1,
              label: {
                //text: moveArr[i].time.toString() + "sn",
              },
              marker: {
                size: 0,
              },
            });
          } else {
            options.annotations.points = [
              {
                x: moveArr[i].time,
                y: dataArr[lastChanged],
                strokeDashArray: 0,
                borderColor: "#000",
                borderWidth: 1,
                label: {
                  //text: moveArr[i].time.toString() + "sn",
                },
                marker: {
                  size: 0,
                },
              },
            ];
          }
        }
      }
      // this[options] = baseOptions;
      // dataArr.splice(lastChanged, dataArr.length - lastChanged, null);
      if (!isCompare) return [dataArr, ansArr];
      return dataArr;
    },
  },
};
</script>
<style scoped>
.subject-info {
  margin: 5px !important;
  align-items: center;
}
.border-box {
  border: 1px #000 solid;
  padding: 5px;
  border-radius: 10px;
  margin: 5px;
  width: 100%;
}
* {
  font-family: "Courier New", Courier, monospace !important;
}
.subject-info {
  margin-top: 1rem;
  border: #000 1px solid;
  padding: 0.5rem;
  font-size: 12px;
  line-height: 1.168rem;
  border-radius: 5px;
}
.client-rows {
  margin-top: -20px;
}
.data-text {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Swiss 721", sans-serif;
}
@page {
  size: A4;
  margin: 0;
  size: landscape;
}

@media print {
  @page {
    size: A4;
    margin: 5mm;
    size: landscape;
    pages: 1;
  }
}
.exercise-wrapper {
  height: 200mm;
  width: 297mm;
}
#app > div {
  height: 200mm;
  width: 297mm;
}
#app {
  height: 200mm;
  width: 297mm;
}
circle {
  opacity: 0 !important;
}
.page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}
/* .page {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
} */
.age-class-avgs > .row {
  border-bottom: 1px solid #000;
  font-size: 11px;
  line-height: 1.168rem;
}
.undergraph-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  font-size: 11px;
  line-height: 1.1rem;
  border: 1px solid #000;
}

.undergraph-table > * > tr > * {
  text-align: center;
}
.undergraph-table > * > tr > td {
  border: 1px solid #000;
  padding: 0.1rem;
}
.seperator {
  border: 1px solid #000;
  width: 1px;
  background-color: #000;
}
.header-row.first {
  border-bottom: #000 1px solid;
  font-weight: bold;
}

/*.age-class-avgs > .row:first-child {
  border-top: 1px solid #000;
}*/
</style>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
