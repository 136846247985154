export const tATLOptions = {
  chart: {
    type: 'line',
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      }
    },
    animations: {
      enabled: false
    }
  },
  tooltip: {
    enabled: false,
    x: { show: false, enabled: false },
    y: { show: false, enabled: false }
  },
  colors: ['#000', '#777'],
  dataLabels: {
    enabled: false,
    textAnchor: 'end',
    background: {
      enabled: false,
      foreColor: '#FFF',
      padding: 4,
      borderWidth: 1,
      borderColor: '#FFF',
      opacity: 1
    },
    style: {
      fontSize: '10px'
    }
  },
  stroke: { curve: 'smooth', width: 3 },
  xaxis: {
    min: 0,
    max: 150,
    stepSize: 30,
    labels: {
      show: false
    }
  },
  yaxis: {
    min: -10,
    max: 60,
    tickAmount: 5,
    position: 'back',
    labels: {
      show: false
    }
  },
  legend: {
    enabled: false
  },
  grid: {
    borderColor: '#000',
    strokeDashArray: 0,
    yaxis: { lines: { show: true, color: '#000' } }
  },
  annotations: {
    position: 'back',
  },
  title: {
    text: 'Dikkat Başarı Grafiği',
    offsetY: 25,
    align: 'center',
    style: {
      color: '#000'
    }
  }
}
export const tLTLOptions = {
  chart: {
    type: 'line',
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      }
    },
    animations: {
      enabled: false
    }
  },
  tooltip: {
    enabled: false,
    x: { show: false, enabled: false },
    y: { show: false, enabled: false }
  },
  colors: ['#000', '#777'],
  dataLabels: {
    enabled: false,
    textAnchor: 'end',
    background: {
      enabled: false,
      foreColor: '#FFF',
      padding: 4,
      borderWidth: 1,
      borderColor: '#FFF',
      opacity: 1
    },
    style: {
      fontSize: '10px'
    }
  },
  stroke: { curve: 'smooth', width: 2 },
  xaxis: {
    min: 0,
    max: 150,
    stepSize: 30,
    labels: {
      show: false
    }
  },
  yaxis: {
    min: -10,
    max: 60,
    tickAmount: 5,
    position: 'back',
    labels: {
      show: false
    }
  },
  legend: {
    enabled: false
  },
  grid: {
    borderColor: '#000',
    strokeDashArray: 0,
    yaxis: { lines: { show: true, color: '#000' } }
  },
  annotations: {
    position: 'back',
  },
  title: {
    text: 'Mantık Başarı Grafiği',
    offsetY: 25,
    align: 'center',
    style: {
      color: '#000'
    }
  }
}
export const tCTLOptions = {
  chart: {
    type: 'line',
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      }
    },
    animations: {
      enabled: false
    }
  },
  tooltip: {
    enabled: false,
    x: { show: false, enabled: false },
    y: { show: false, enabled: false }
  },
  colors: ['#000', '#777'],
  dataLabels: {
    enabled: false,
    textAnchor: 'end',
    background: {
      enabled: false,
      foreColor: '#FFF',
      padding: 4,
      borderWidth: 1,
      borderColor: '#FFF',
      opacity: 1
    },
    style: {
      fontSize: '10px'
    }
  },
  stroke: { curve: 'smooth', width: 2 },
  xaxis: {
    min: 0,
    max: 150,
    stepSize: 30,
    labels: {
      show: false
    }
  },
  yaxis: {
    min: -10,
    max: 60,
    tickAmount: 5,
    position: 'back',
    labels: {
      show: false
    }
  },
  legend: {
    enabled: false
  },
  grid: {
    borderColor: '#000',
    strokeDashArray: 0,
    yaxis: { lines: { show: true, color: '#000' } }
  },
  annotations: {
    position: 'back',
  },
  title: {
    text: 'Konsantrasyon Başarı Grafiği',
    offsetY: 25,
    align: 'center',
    style: {
      color: '#000'
    }
  }
}
export const tMTLOptions = {
  chart: {
    type: 'line',
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      }
    },
    animations: {
      enabled: false
    }
  },
  tooltip: {
    enabled: false,
    x: { show: false, enabled: false },
    y: { show: false, enabled: false }
  },
  colors: ['#000', '#777'],
  dataLabels: {
    enabled: false,
    textAnchor: 'end',
    background: {
      enabled: false,
      foreColor: '#FFF',
      padding: 4,
      borderWidth: 1,
      borderColor: '#FFF',
      opacity: 1
    },
    style: {
      fontSize: '10px'
    }
  },
  stroke: { curve: 'smooth', width: 2 },
  xaxis: {
    min: 0,
    max: 150,
    stepSize: 30,
    labels: {
      show: false
    }
  },
  yaxis: {
    min: -5,
    max: 30,
    tickAmount: 5,
    position: 'back',
    labels: {
      show: false
    }
  },
  legend: {
    enabled: false
  },
  grid: {
    borderColor: '#000',
    strokeDashArray: 0,
    yaxis: { lines: { show: true, color: '#000' } }
  },
  annotations: {
    position: 'back'
  },
  title: {
    text: 'Hafıza Başarı Grafiği',
    offsetY: 25,
    align: 'center',
    style: {
      color: '#000'
    }
  }
}
export const tGTLOptions = {
  chart: {
    type: 'line',
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      }
    },
    animations: {
      enabled: false
    }
  },
  tooltip: {
    enabled: false,
    x: { show: false, enabled: false },
    y: { show: false, enabled: false }
  },
  colors: ['#000', '#777'],
  dataLabels: {
    enabled: false,
    textAnchor: 'end',
    background: {
      enabled: false,
      foreColor: '#FFF',
      padding: 4,
      borderWidth: 1,
      borderColor: '#FFF',
      opacity: 1
    },
    style: {
      fontSize: '10px'
    }
  },
  stroke: { curve: 'smooth', width: 2 },
  xaxis: {
    min: 0,
    max: 600,
    stepSize: 30,
    labels: {
      show: false
    }
  },
  yaxis: {
    min: -10,
    max: 60,
    tickAmount: 5,
    position: 'back',
    labels: {
      show: false
    }
  },
  legend: {
    enabled: false
  },
  grid: {
    borderColor: '#000',
    strokeDashArray: 0,
    yaxis: { lines: { show: true, color: '#000' } }
  },
  annotations: {
    position: 'back'
  },
  title: {
    text: 'Genel',
    offsetY: 25,
    align: 'center',
    style: {
      color: '#000'
    }
  }
}
